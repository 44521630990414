/* TODO: Creo que esto se puede borrar */
/**
*   Html Spinner boostrap app
*/

.spinner-container {
  align-items: center;
  background: white;
  display: flex;
  height: 100vh;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-weight: 100;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;

  span {
    margin-bottom: 10px;
  }

  i {
    font-size: 10px;
    font-style: italic;
  }

  .sk-circle {
    margin-top: 50px;
    height: 40px;
    position: relative;
    width: 40px;

    .sk-child {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .sk-child:before {
      animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
      background-color: #333;
      border-radius: 100%;
      content: '';
      display: block;
      margin: 0 auto;
      height: 15%;
      width: 15%;
    }

    .sk-circle2 {
      transform: rotate(30deg);
    }

    .sk-circle3 {
      transform: rotate(60deg);
    }

    .sk-circle4 {
      transform: rotate(90deg);
    }

    .sk-circle5 {
      transform: rotate(120deg);
    }

    .sk-circle6 {
      transform: rotate(150deg);
    }

    .sk-circle7 {
      transform: rotate(180deg);
    }

    .sk-circle8 {
      transform: rotate(210deg);
    }

    .sk-circle9 {
      transform: rotate(240deg);
    }

    .sk-circle10 {
      transform: rotate(270deg);
    }

    .sk-circle11 {
      transform: rotate(300deg);
    }

    .sk-circle12 {
      transform: rotate(330deg);
    }

    .sk-circle2:before {
      animation-delay: -1.1s;
    }

    .sk-circle3:before {
      animation-delay: -1s;
    }

    .sk-circle4:before {
      animation-delay: -0.9s;
    }

    .sk-circle5:before {
      animation-delay: -0.8s;
    }

    .sk-circle6:before {
      animation-delay: -0.7s;
    }

    .sk-circle7:before {
      animation-delay: -0.6s;
    }

    .sk-circle8:before {
      animation-delay: -0.5s;
    }

    .sk-circle9:before {
      animation-delay: -0.4s;
    }

    .sk-circle10:before {
      animation-delay: -0.3s;
    }

    .sk-circle11:before {
      animation-delay: -0.2s;
    }

    .sk-circle12:before {
      animation-delay: -0.1s;
    }
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
